<template>
  <b-overlay :show="loading">
    <div class="formBoder">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Tax Book Name" vid="name" rules="required" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  id="name"
                  label-for="name"
                >
                <template v-slot:label>
                  Tax Book Name
                </template>
                  <b-form-input
                    id="name"
                    v-model="formField.name"
                    placeholder="Enter Tax Book Name"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="External Url" vid="external_url" rules="" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  id="external_url"
                  label-for="external_url"
                >
                <template v-slot:label>
                  External Url
                </template>
                  <b-form-input
                    id="external_url"
                    v-model="formField.external_url"
                    placeholder="Enter External Url"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Serial No" vid="serial" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  id="serial"
                  label-for="serial"
                >
                <template v-slot:label>
                  Serial No
                </template>
                  <b-form-input
                    type="number"
                    id="serial"
                    v-model="formField.serial"
                    placeholder="Enter Serial No"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        <div class="row mt-3">
          <div class="col-sm-3"></div>
          <div class="col text-right">
              <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
              &nbsp;
              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
          </div>
        </div>
      </b-form>
      </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      formField: {
        tax_type_id: '',
        tax_subtype_id: '',
        name: '',
        external_url: '',
        serial: '',
        section_title: '',
        access_type: 'Paid'
      },
      errors: [],
      valid: null,
      loading: false,
      bookSectionFullList: [],
      bookSectionTreeList: [],
      bookSectionParentList: [],
      subsectionList: []
    }
  },
  created () {
    if (this.editItem) {
      const editData = JSON.parse(this.editItem)
      this.formField = editData
      this.formField.access_type = this.formField.access_type === false ? 'Free' : 'Paid'
    } else {
      this.formField.tax_subtype_id = this.$route.params.id
      this.getLastSerial()
    }
  },
  computed: {
    accessTypeList: function () {
      const list = [
        { value: 'Free', text: 'Free' },
        { value: 'Paid', text: 'Paid' }
      ]
      return list
    }
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.formField.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_tax_book_data', this.formField)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_tax_book_data', this.formField)
      }
      this.loading = false
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    async getLastSerial () {
      const result = await RestApi.getData(apiBaseURL, 'admin/ajax/get_tax_book_serial')
      if (result.success) {
        this.formField.serial = result.data
      }
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
